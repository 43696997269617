<template>
  <div :class="$style.working">
    <div :class="$style.wrapper">
      <a :href="$configData.addevents_link" target="_blank">
        <el-button size="small" type="primary">Просмотреть</el-button>
      </a>
      <el-button
        size="small"
        type="primary"
        :class="$style.button"
        @click="submitForm()"
      >
        Сохранить контент
      </el-button>
    </div>
    <el-form
      :model="form"
      ref="form"
      :rules="rules"
      label-position="left"
      label-width="12.5rem"
      :class="$style.form"
    >
      <h3 :class="$style.title">С нами работают</h3>
      <el-form-item label="Название блока" prop="title">
        <el-input v-model="form.workingWithUs.title" />
      </el-form-item>

      <div :class="$style.mainBanner">
        <Container
          @drop="onDropWorkingWithUs"
          :non-drag-area-selector="'.drag-disabled'"
        >
          <Draggable
            v-for="(card, index) in form.workingWithUs.cards"
            :key="index"
          >
            <div :class="$style.workingWidth">
              <div :class="$style.workingWidthItems">
                <el-form-item :label="'Карточка ' + (index + 1)">
                  <Uploader
                    without-caption
                    :limit="1"
                    :files="
                      card.image.original
                        ? [
                            {
                              original: card.image.original,
                            },
                          ]
                        : []
                    "
                    @upload="uploadImage($event, index)"
                  />
                </el-form-item>
              </div>
              <div :class="$style.controls">
                <div :class="$style.removeButton">
                  <el-button
                    type="danger"
                    plain
                    circle
                    icon="el-icon-delete"
                    :class="($style.remove, 'drag-disabled')"
                    @click="removeWorkingWithUs(index)"
                  />
                </div>
                <div :class="$style.dragIcon">
                  <Icon name="draggable" :class="$style.icon" />
                </div>
              </div>
            </div>
          </Draggable>
        </Container>
        <el-button
          size="small"
          type="primary"
          :class="$style.button"
          @click="addAchievementElem()"
          :disabled="form?.workingWithUs?.cards?.length > 12"
          >Добавить
        </el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
import Icon from '@/components/atoms/Icon'
import Uploader from '@/components/moleculs/AddwineUploader.vue'
import delivery from '@/delivery'

export default {
  components: { Icon, Uploader },

  data() {
    return {
      form: {
        workingWithUs: {
          title: '',
          cards: [],
        },
      },
      rules: {
        'workingWithUs.title': [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur',
          },
        ],
      },
    }
  },

  created() {
    this.getContent()
  },

  methods: {
    uploadImage(images, index) {
      this.form.workingWithUs.cards[index].image.original = images.length
        ? images[0].original
        : ''
    },

    async getContent() {
      const loading = this.$loading({
        lock: true,
      })

      const { value, error } =
        await delivery.ContentServiceCore.PagesActions.get('common', 'addevent')
      loading.close()

      if (error) return

      this.form = { ...value.content }
    },

    async submitForm() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          const loading = this.$loading({
            lock: true,
          })

          const data = {
            ...this.form,
          }

          const result = await delivery.ContentServiceCore.PagesActions.update(
            'common',
            'addevent',
            data,
          )
          loading.close()

          if (result.error) {
            this.$message({
              message: 'Ошибка редактирования страницы',
              type: 'error',
            })
            return
          }
          this.$message({
            message: 'Страница успешно отредактирована',
            type: 'success',
          })
        }
      })
    },

    addAchievementElem() {
      if (this.form?.workingWithUs?.cards?.length > 12) {
        return
      }
      this.form?.workingWithUs?.cards.push({
        image: {
          original: '',
          caption: 'no_photo',
        },
      })
    },
    removeWorkingWithUs(index) {
      this.form?.workingWithUs?.cards.splice(index, 1)
    },

    applyDrag(arr, dragResult) {
      const { removedIndex, addedIndex, payload } = dragResult
      if (removedIndex === null && addedIndex === null) return arr

      const result = [...arr]
      let itemToAdd = payload

      if (removedIndex !== null) {
        itemToAdd = result.splice(removedIndex, 1)[0]
      }

      if (addedIndex !== null) {
        result.splice(addedIndex, 0, itemToAdd)
      }

      return result
    },
    onDropWorkingWithUs(dropResult) {
      this.form.workingWithUs.cards = this.applyDrag(
        this.form.workingWithUs.cards,
        dropResult,
      )
    },
  },
}
</script>

<style lang="scss" module>
.working {
  padding: 1rem;

  .wrapper {
    @include stickyWrapper;
    .button {
      margin-left: 1rem;
    }
  }

  .title {
    margin-bottom: 2rem;
  }

  .workingWidth {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    border: 1px solid $light-gray;
    padding: 0.5rem;
    border-radius: 0.3rem;
    margin-bottom: 1rem;

    .workingWidthItems {
      width: 100%;
    }

    .controls {
      margin-top: 30px;
      display: flex;
      gap: 1rem;
    }
  }
  .icon {
    height: 2.5rem;
    width: 2.5rem;
  }
}
</style>
