var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.$style.working},[_c('div',{class:_vm.$style.wrapper},[_c('a',{attrs:{"href":_vm.$configData.addevents_link,"target":"_blank"}},[_c('el-button',{attrs:{"size":"small","type":"primary"}},[_vm._v("Просмотреть")])],1),_c('el-button',{class:_vm.$style.button,attrs:{"size":"small","type":"primary"},on:{"click":function($event){return _vm.submitForm()}}},[_vm._v(" Сохранить контент ")])],1),_c('el-form',{ref:"form",class:_vm.$style.form,attrs:{"model":_vm.form,"rules":_vm.rules,"label-position":"left","label-width":"12.5rem"}},[_c('h3',{class:_vm.$style.title},[_vm._v("С нами работают")]),_c('el-form-item',{attrs:{"label":"Название блока","prop":"title"}},[_c('el-input',{model:{value:(_vm.form.workingWithUs.title),callback:function ($$v) {_vm.$set(_vm.form.workingWithUs, "title", $$v)},expression:"form.workingWithUs.title"}})],1),_c('div',{class:_vm.$style.mainBanner},[_c('Container',{attrs:{"non-drag-area-selector":'.drag-disabled'},on:{"drop":_vm.onDropWorkingWithUs}},_vm._l((_vm.form.workingWithUs.cards),function(card,index){return _c('Draggable',{key:index},[_c('div',{class:_vm.$style.workingWidth},[_c('div',{class:_vm.$style.workingWidthItems},[_c('el-form-item',{attrs:{"label":'Карточка ' + (index + 1)}},[_c('Uploader',{attrs:{"without-caption":"","limit":1,"files":card.image.original
                      ? [
                          {
                            original: card.image.original,
                          },
                        ]
                      : []},on:{"upload":function($event){return _vm.uploadImage($event, index)}}})],1)],1),_c('div',{class:_vm.$style.controls},[_c('div',{class:_vm.$style.removeButton},[_c('el-button',{class:(_vm.$style.remove, 'drag-disabled'),attrs:{"type":"danger","plain":"","circle":"","icon":"el-icon-delete"},on:{"click":function($event){return _vm.removeWorkingWithUs(index)}}})],1),_c('div',{class:_vm.$style.dragIcon},[_c('Icon',{class:_vm.$style.icon,attrs:{"name":"draggable"}})],1)])])])}),1),_c('el-button',{class:_vm.$style.button,attrs:{"size":"small","type":"primary","disabled":_vm.form?.workingWithUs?.cards?.length > 12},on:{"click":function($event){return _vm.addAchievementElem()}}},[_vm._v("Добавить ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }